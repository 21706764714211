import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { FcCellPhone, FcFeedback } from "react-icons/fc";
import { Collapse } from "react-collapse";
import avatar from "../../assets/images/avatar.png";
import { useTranslation } from "react-i18next";

const MarkaziyAprtItem = ({ item }) => {
  const [showInfo, setShowInfo] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="lg:flex md:flex lg:mx-0 md:mx-4 mx-8">
        <div className="w-[330px] h-[400px] mr-2">
          <img
            src={item.post_img ? item.post_img : avatar}
            alt="Markaziy apparat"
            className="object-cover rounded shadow-2xl w-[300px] h-[400px]"
          />
        </div>

        <div className="flex flex-col gap-3 ">
          <p className="lg:w-[600px] md:w-[400px] text-xl font-semibold lm:mt-0 md:mt-0 mt-4">
            {item.job}
          </p>
          <p className="lg:w-[600px] text-2xl font-bold">{item.f_name}</p>
          <p className="flex items-center gap-2">
            <FcCellPhone fontSize={25} />
            {item.phone}
          </p>
          <p className="flex items-center gap-2">
            <FcFeedback fontSize={25} />
            {item.email}
          </p>
          <div onClick={() => setShowInfo(!showInfo)}>
            <button className="flex items-center gap-2 border-2 px-2 rounded bg-[#15616D] border-[#15616D] hover:border-[#FF7D00] hover:bg-[#FF7D00]  text-white  lg:mt-[140px] md:mt-[100px] transition-all duration-200 ">
              {t("Mutahassislar")}
              <span>
                {showInfo ? (
                  <IoIosArrowDown fontSize={16} />
                ) : (
                  <IoIosArrowForward fontSize={16} />
                )}
              </span>
            </button>
          </div>
          <Collapse isOpened={showInfo}>
            {item.workers.map((worker, index) => (
              <div key={index} className="flex mb-[25px] gap-4">
                <div>
                  <img
                    src={worker.image ? worker.image : avatar}
                    alt="mutahassislar"
                    width="230px"
                    height="230px"
                    className="object-cover rounded shadow-2xl"
                  />
                </div>
                <div>
                  <p className="lg:text-xl">{worker.job}</p>
                  <p className="text-base">{worker.f_name}</p>
                </div>
              </div>
            ))}
          </Collapse>
        </div>
      </div>
      <div className="border-b-2 border-[#0f3460] py-[20px] mb-[50px]"></div>
    </>
  );
};
export default MarkaziyAprtItem;
