import React from "react";
import { useTranslation } from "react-i18next";
import qibray from "../../assets/images/qibray.png";
import ugol1 from "../../assets/images/1.svg";
import ugol2 from "../../assets/images/2.svg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#fffae7] relative my-[100px] rounded-tr-[50px] rounded-bl-[50px]">
      <img src={ugol1} alt="" className="absolute top-[-65px]" />
      <img src={ugol2} alt="" className="absolute bottom-[-65px] right-0" />
      <div className="flex justify-center items-center px-[100px] gap-8 h-[400px]">
        <div>
          <img src={qibray} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[20px] mt-[-50px] text-[36px] text-#121221">
            {t("Qibray tumani haqida")}
          </h4>
          <p>
            {t(
              "Qibray tumani 1964-yi 31-dekabrda tashkil topgan. Yer maydoni 481,41 kv.km. Umumiy chegara uzunligi 155 km, shundan 56 km Qozoziston Respublikasi, 19 km Toshkent shahri, 25 km Chirchiq shahri, 21 km Bo’stonliq tumani, 18 km Toshkent tumani, 16 km Yuqorichirchiq tumani bilan iborat. Aholi soni 185,3 ming kishi (01.01.2023 yil holatida). 79,3 ming kishi (46%) shaharda, 93 ming kishi (54%) qishloqda istiqomat qiladi. Aholi zichligi 1 kv. kmga 358 kishi. Tumanda 2 ta shaharchalar, 68 ta mahalla fuqarolar yig’ini mavjud. Xonadonlar soni 40615 ta. Oilalar soni 52971 ta."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
